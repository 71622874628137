<template>
  <main id="content" role="main">

    <!-- ========== Hero Section ========== -->
    <div class="position-relative bg-img-hero" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-partner-hero.svg') + ')'}">
      <div class="container space-top-3 space-top-lg-4 space-bottom-2 space-bottom-lg-3 position-relative z-index-2">
        <div class="row justify-content-lg-between mb-7">
          <div class="col-md-6 col-lg-6">
            <!-- Info -->
            <div class="mb-5">
              <span class="d-block small font-weight-bold text-cap mb-2">Our Partners</span>
              <h1>Build a Strong Network of API Economy</h1>
            </div>

            <!-- End Info -->
          </div>

          <div class="col-md-6 d-md-inline-block">
            <!-- SVG Illustration -->
            <figure class="w-100" data-aos="fade-up">
              <img class="img-fluid" src="@/assets/svg/illustrations/partners-hero.svg" alt="Image Description">
            </figure>
            <!-- End SVG Illustration -->
          </div>
        </div>
      </div>
      <!-- SVG Shape -->
      <figure class="position-absolute right-0 bottom-0 left-0 mb-n1">
        <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
          <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
        </svg>
      </figure>
      <!-- End SVG Shape -->
    </div>
    <!-- ========== End Hero Section ========== -->

    <div class="container space-2">
      <div class="row">
        <div class="col-lg-4 mb-4 mb-md-5 mb-lg-0 transition-3d-hover">
          <!-- Card -->
          <a class="card shadow-soft h-100 text-center" href="#" target="_blank">
            <div class="card-body pt-7 px-7">
              <figure class="avatar avatar-xl mx-auto mb-5">
                <img class="avatar-img" src="@/assets/svg/icons/icon-69.svg">
              </figure>
              <h3>API Product Partners</h3>
              <p class="text-body">Add API Product Partners Description Here. Add API Product Partners Description Here.</p>
            </div>
            <div class="card-footer border-0 pt-0 pb-7 px-7">
              <span class="font-weight-bold">API Product Partners Request <i class="fas fa-angle-right fa-sm ml-1" /></span>
            </div>
          </a>
          <!-- End Card -->
        </div>

        <div class="col-lg-4 mb-4 mb-md-5 mb-lg-0 transition-3d-hover">
          <!-- Card -->
          <a class="card shadow-soft h-100 text-center" href="#" target="_blank">
            <div class="card-body pt-7 px-7">
              <figure class="avatar avatar-xl mx-auto mb-5">
                <img class="avatar-img" src="@/assets/svg/icons/icon-68.svg">
              </figure>
              <h3>Technology Partners</h3>
              <p class="text-body">Add Technology Partners Description Here. Add Technology Partners Description Here.</p>
            </div>
            <div class="card-footer border-0 pt-0 pb-7 px-7">
              <span class="font-weight-bold">Technology Partners Request <i class="fas fa-angle-right fa-sm ml-1" /></span>
            </div>
          </a>
          <!-- End Card -->
        </div>

        <div class="col-lg-4 transition-3d-hover">
          <!-- Card -->
          <a class="card shadow-soft h-100 text-center" href="#" target="_blank">
            <div class="card-body pt-7 px-7">
              <figure class="avatar avatar-xl mx-auto mb-5">
                <img class="avatar-img" src="@/assets/svg/icons/icon-7.svg">
              </figure>
              <h3>Community Partners</h3>
              <p class="text-body">Add Community Partners Description Here. Add Community Partners Description Here.</p>
            </div>
            <div class="card-footer border-0 pt-0 pb-7 px-7">
              <span class="font-weight-bold">Community Partners Request <i class="fas fa-angle-right fa-sm ml-1" /></span>
            </div>
          </a>
          <!-- End Card -->
        </div>
      </div>
    </div>

    <!-- ========== Partners Section ========== -->
    <div class="container space-2">
      <div class="text-center mb-4">
        <h2 class="divider divider-text">Featured Partners</h2>
      </div>
      <PartnerCarousel :items="partner_list" />
    </div>
    <!-- ========== End Partners Section ========== -->

    <!-- ==========  CTA Section ==========  -->
    <div class="container space-2 space-bottom-3">
      <ContactUsBar :detail="{ title: 'Interested in Partnering with Us?' }" />
    </div>
    <!-- ========== End CTA Section ==========  -->

  </main>
</template>

<script>
import 'bootstrap'
const $ = require('jquery')
import PartnerCarousel from '@/components/partner-carousel'
import ContactUsBar from '@/components/contact-us-bar'
import HSGoTo from '../../assets/vendor/hs-go-to/src/js/hs-go-to'
import { mapGetters } from 'vuex'
export default {
  name: 'Partner',
  components: {
    PartnerCarousel,
    ContactUsBar
  },
  data() {
    return {
      partner_list: []
    }
  },
  computed: {
    ...mapGetters([
      'partners',
      'practices'
    ])
  },
  async mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
    $('.js-slick-carousel').each(function() {
      $.HSCore.components.HSSlickCarousel.init($(this))
    })
  },
  created() {
    this.partner_list = this.$store.getters.partners.partner_list
    this.practice_list = this.$store.getters.practices.practice_list
    this.testimonial_list = this.$store.getters.partners.testimonial_list
  },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: '%s | Partners',
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'beNovelty | Partners' },
        { property: 'og:description', content: 'Join Us and Build a Strong Network of API Economy.' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/partner.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/partners' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../assets/vendor/slick-carousel/slick/slick.css");
</style>
